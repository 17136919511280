<template>
  <div class="container-xs">
    <AppBackHeader to="/search/summary">PASSENGER DETAILS</AppBackHeader>
    <form-input
      v-model="search"
      type="text"
      placeholder="Search Travel Companion"
      style="margin-top: 3rem"
      @input="$event.target.composing = false"
    />
    <div class="trip-passengers">
      <div
        v-for="traveler in filteredTravelers"
        class="app-card-clickable"
        @click="selectTraveler(traveler.id)"
      >
        {{ traveler.fullName }}
      </div>
      <router-link
        :to="`/search/travelers/create/${travelerType}`"
        class="app-card-clickable"
        style="color: var(--color-primary)"
      >
        + Add a new person
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppBackHeader from '@/components-rf/AppBackHeader.vue'
import Fuse from 'fuse.js'

export default {
  name: 'TripTravelersSelect',
  components: { AppBackHeader },
  created() {
    const hasTraveler = {
      adult: this.hasTravelers.adults,
      child: this.hasTravelers.children,
      infant: this.hasTravelers.infants,
    }[this.travelerType]
    if (hasTraveler) this.$router.push('/search/summary')
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    ...mapState('SearchState', ['passengers']),
    ...mapGetters('SearchState', ['hasTravelers']),
    ...mapState('TravelerModule', ['travelers']),
    ...mapGetters('TitleModule', ['getFullName']),
    travelerType() {
      const q = this.$route.query
      return q.adult === null
        ? 'adult'
        : q.child === null
        ? 'child'
        : q.infant === null
        ? 'infant'
        : 'adult'
    },
    displayTravelers() {
      return this.travelers
        .filter(traveler => {
          const isCorrectType =
            traveler.travelerType.toLowerCase() === this.travelerType
          const isNotPassenger = !this.passengers.includes(traveler.id)
          return isCorrectType && isNotPassenger
        })
        .map(traveler => ({
          ...traveler,
          fullName: this.getFullName(traveler),
        }))
    },
    filteredTravelers() {
      if (!this.search) return this.displayTravelers
      return new Fuse(this.displayTravelers, {
        findAllMatches: true,
        threshold: 0.5,
        keys: ['fullName'],
      })
        .search(this.search)
        .map(el => el.item)
    },
  },
  methods: {
    selectTraveler(id) {
      this.$store.commit('SearchState/selectTraveler', id)
      this.$router.push('/search/summary')
    },
  },
}
</script>

<style scoped lang="scss">
.trip-passengers {
  margin-top: 1.5rem;
  display: grid;
  gap: 0.75rem;
}
</style>
